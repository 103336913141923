import React from "react";
import { graphql } from "gatsby";
import rehypeReact from "rehype-react";
import Img from "gatsby-image";
import styled from "styled-components";
import Color from "color";

import Layout from "../components/layout";
import Container from "../components/container";
import { ContainerWrapper, Column, Asset } from "../components/markdown-layout";
import Content from "../components/markdown-content";
import ResponsiveVideo from "../components/responsive-video";
import HorizontalRule from "../components/horizontal-rule";
import Nav from "../components/nav";
import SEO from "../components/seo";

import { type } from "../theme/typography";
import theme from "../theme/theme";

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    container: ContainerWrapper,
    column: Column,
    asset: Asset,
    responsivevideo: ResponsiveVideo
  }
}).Compiler;

// Header component on writing template is a little complex
// Header
// ⊢ HeaderImage: Styled Gatsby Img component
// ⊢ Gradient: Contains two gradients that overlay header image to blend it into background. Not using mask-image because of lack of IE support
// ⊢ Header Meta
//   ⊢ Header Title
//   ⊢ Header Abstract

const Header = styled.header`
  position: relative;
  overflow: hidden;
  margin-bottom: ${theme.spacing.xlarge};
  ${theme.breakpoint.down("small")`
    margin-bottom: 36px;
  `};
`;

const HeaderImage = styled(Img)`
  margin: 0 auto;
  position: absolute !important;
  top: 0;
  width: 100%;
  height: 600px;
  z-index: 0;
  opacity: 0.6;
  ${theme.breakpoint.down("small")`
      height: 380px;
  `};
`;

/* prettier-ignore */
const HeaderGradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 600px;
  ${theme.breakpoint.down("small")`
      height: 380px;
  `};
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 40%;
    z-index: 10;
  }
  &:before {
    top: 0;
    height: 200px;
    background: linear-gradient(
      to bottom,
      ${props => Color(props.backgroundColor).hsl().string()} 0%,
      ${props => Color(props.backgroundColor).alpha(0.987).hsl().string()} 8.1%,
      ${props => Color(props.backgroundColor).alpha(0.951).hsl().string()} 15.5%,
      ${props => Color(props.backgroundColor).alpha(0.896).hsl().string()} 22.5%,
      ${props => Color(props.backgroundColor).alpha(0.825).hsl().string()} 29%,
      ${props => Color(props.backgroundColor).alpha(0.741).hsl().string()} 35.3%,
      ${props => Color(props.backgroundColor).alpha(0.648).hsl().string()} 41.2%,
      ${props => Color(props.backgroundColor).alpha(0.55).hsl().string()} 47.1%,
      ${props => Color(props.backgroundColor).alpha(0.45).hsl().string()} 52.9%,
      ${props => Color(props.backgroundColor).alpha(0.352).hsl().string()} 58.8%,
      ${props => Color(props.backgroundColor).alpha(0.259).hsl().string()} 64.7%,
      ${props => Color(props.backgroundColor).alpha(0.175).hsl().string()} 71%,
      ${props => Color(props.backgroundColor).alpha(0.104).hsl().string()} 77.5%,
      ${props => Color(props.backgroundColor).alpha(0.049).hsl().string()} 84.5%,
      ${props => Color(props.backgroundColor).alpha(0.013).hsl().string()} 91.9%,
      ${props => Color(props.backgroundColor).alpha(0).hsl().string()} 100%
    );
    ${theme.breakpoint.down("small")`
      height: 100px;
  `};
  }
  &:after {
    bottom: 0;
    height: 280px;
    background: linear-gradient(
      to top,
      ${props => Color(props.backgroundColor).hsl().string()} 0%,
      ${props => Color(props.backgroundColor).alpha(0.987).hsl().string()} 8.1%,
      ${props => Color(props.backgroundColor).alpha(0.951).hsl().string()} 15.5%,
      ${props => Color(props.backgroundColor).alpha(0.896).hsl().string()} 22.5%,
      ${props => Color(props.backgroundColor).alpha(0.825).hsl().string()} 29%,
      ${props => Color(props.backgroundColor).alpha(0.741).hsl().string()} 35.3%,
      ${props => Color(props.backgroundColor).alpha(0.648).hsl().string()} 41.2%,
      ${props => Color(props.backgroundColor).alpha(0.55).hsl().string()} 47.1%,
      ${props => Color(props.backgroundColor).alpha(0.45).hsl().string()} 52.9%,
      ${props => Color(props.backgroundColor).alpha(0.352).hsl().string()} 58.8%,
      ${props => Color(props.backgroundColor).alpha(0.259).hsl().string()} 64.7%,
      ${props => Color(props.backgroundColor).alpha(0.175).hsl().string()} 71%,
      ${props => Color(props.backgroundColor).alpha(0.104).hsl().string()} 77.5%,
      ${props => Color(props.backgroundColor).alpha(0.049).hsl().string()} 84.5%,
      ${props => Color(props.backgroundColor).alpha(0.013).hsl().string()} 91.9%,
      ${props => Color(props.backgroundColor).alpha(0).hsl().string()} 100%
    );
    ${theme.breakpoint.down("small")`
      height: 100px;
  `};
  }
`;

const HeaderMeta = styled(Container)`
  max-width: ${parseFloat(theme.variables.siteMeasure) +
    parseFloat(theme.variables.sitePadding * 2) +
    "px"};
  position: relative;
  z-index: 2;
  margin-top: ${theme.spacing.xlarge};
`;

const HeaderTitle = styled.h1`
  ${type("workHeader1")};
  color: ${props => Color(props.titleColor).string()};
  ${theme.breakpoint.down("small")`
    ${type("writingHeader1mobile")};
  `};
`;

const HeaderDate = styled.strong`
  color: ${props => props.bodyColor};
`;

const HeaderAbstract = styled.p`
  ${type("body")};
  margin-top: ${theme.spacing.base};
  color: ${props =>
    Color(props.bodyColor)
      .fade(0.2)
      .string()};
  ${theme.breakpoint.down("small")`
    ${type("bodySmall")};
  `};
`;

const HeaderHorizontalRule = styled(HorizontalRule)`
  margin-top: ${theme.spacing.xlarge};
  ${theme.breakpoint.down("small")`
    margin-top: 36px;
  `};
`;

export default ({ data }) => {
  const post = data.markdownRemark;
  const image = post.frontmatter.cardImage
    ? post.frontmatter.cardImage.childImageSharp.resize
    : null;

  return (
    <React.Fragment>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.abstractShort}
        image={image}
      />

      <Layout
        backgroundColor={post.frontmatter.backgroundColor}
        bodyColor={post.frontmatter.bodyColor}
        footerColor={post.frontmatter.footerColor}
        navColor={post.frontmatter.bodyColor}
        showNav="false"
      >
        <Header>
          <HeaderImage
            sizes={post.frontmatter.headerImage.childImageSharp.fluid}
          />

          <HeaderGradient backgroundColor={post.frontmatter.backgroundColor} />

          <Nav navColor={post.frontmatter.bodyColor} />

          <HeaderMeta>
            <HeaderTitle titleColor={post.frontmatter.bodyColor}>
              {post.frontmatter.title}
            </HeaderTitle>
            <HeaderAbstract bodyColor={post.frontmatter.bodyColor}>
              <HeaderDate bodyColor={post.frontmatter.bodyColor}>
                {post.frontmatter.date}
                {"—"}
                {post.fields.readingTime.text}
                {" / "}
              </HeaderDate>
              {post.frontmatter.abstractLong}
            </HeaderAbstract>
            <HeaderHorizontalRule
              ruleColor={Color(post.frontmatter.footerColor)
                .fade(0.7)
                .string()}
            />
          </HeaderMeta>
        </Header>

        <Content
          backgroundColor={post.frontmatter.backgroundColor}
          bodyColor={post.frontmatter.bodyColor}
          highlightColor={post.frontmatter.highlightColor}
          footerColor={post.frontmatter.footerColor}
        >
          {renderAst(post.htmlAst)}
        </Content>
      </Layout>
    </React.Fragment>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
        subtitle
        date(formatString: "DD MMMM YYYY")
        bodyColor
        backgroundColor
        highlightColor
        footerColor
        abstractShort
        abstractLong
        headerImage {
          childImageSharp {
            fluid(maxWidth: 2000, srcSetBreakpoints: [1500, 2000, 2500]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cardImage {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      fields {
        slug
        readingTime {
          text
          words
        }
      }
    }
  }
`;
