// A component to control the layout of Markdown.
// Used for work and writing templates.

// Exports three components:

// <ContainerWrapper>: Return all children into a container component. If there are Column children, wrap in a display: flex columnGroup container.
// <Column>: A wrapper for each column. Columns automatically divide up available space in parent container evenly. To override, pass percentage via columnsize prop, e.g. columnsize="33". If only a single column, width is determined by theme.variables.sitemeasure.
// <Asset>: For assets (images, video, etc) outside of <ContainerWrapper> and <Column>. By default wraps asset in a <Container> component. Use prop width="full" to bypass this / set to 100% window width.

import React from "react";
import styled from "styled-components";
import theme from "../theme/theme";

import Container from "./container";

let size;

const Column = props => {
  const ColumnWrapper = styled.div`
    flex: 0 1 ${props => props.columnsize};
    padding: ${props =>
      props.columnsize === "100%" ? "0" : "0 " + theme.spacing.small};
    max-width: ${props =>
      props.columnsize === "100%"
        ? theme.variables.siteMeasure + "px"
        : "none"};
    margin-left: ${props => (props.columnsize === "100%" ? "auto" : "0")};
    margin-right: ${props => (props.columnsize === "100%" ? "auto" : "0")};
    .gatsby-resp-image-figure,
    .gatsby-resp-image-wrapper,
    .responsive-video-figure {
      margin: ${theme.spacing.base} 0;
      &:first-child {
        margin-top: 0;
      }
    }
    .gatsby-resp-image-figure > .gatsby-resp-image-wrapper {
      margin: 0;
    }
    figure > .responsive-video-wrapper {
      margin-top: ${theme.spacing.base} !important;
    }
  `;

  return (
    <ColumnWrapper columnsize={size + "%"} {...props}>
      {props.children}
    </ColumnWrapper>
  );
};

Column.displayName = "Column";

const Asset = props => {
  const StyledAsset = styled.div`
    margin: ${theme.spacing.large} 0;
    .gatsby-resp-image-figure,
    .gatsby-resp-image-wrapper {
      max-width: 100% !important;
    }
    .gatsby-resp-image-figure + .gatsby-resp-image-wrapper,
    .gatsby-resp-image-wrapper + .gatsby-resp-image-figure {
      margin: ${theme.spacing.small} 0;
    }
  `;

  const assetSnippet = <StyledAsset {...props}>{props.children}</StyledAsset>;

  return (
    <React.Fragment>
      {props.width === "full" ? (
        <React.Fragment>{assetSnippet}</React.Fragment>
      ) : (
        <Container>{assetSnippet}</Container>
      )}
    </React.Fragment>
  );
};

const ContainerWrapper = props => {
  let columnCount = 0;

  React.Children.forEach(props.children, function(child) {
    if (child.type.displayName === "Column") {
      columnCount++;
    }
    size = 100 / columnCount;
  });

  const ColumnGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -24px;

    // If h2 is inside of a container, but outside of an individual column, it needs padding to account for column gap.
    > h2 {
      padding: 0 ${theme.spacing.small};
    }
  `;

  return (
    <Container>
      {columnCount > 1 ? (
        <ColumnGroup>{props.children}</ColumnGroup>
      ) : (
        <React.Fragment>{props.children}</React.Fragment>
      )}
    </Container>
  );
};

export { ContainerWrapper, Column, Asset };
